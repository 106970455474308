<template>
  <div>
    <CRow>
      <CCol col="12" xl="7">
        <CRow>
          <CCol col="12" xl="12">
            <Summation ref="summationView" @click="onClick" />
          </CCol>
        </CRow>      
        <CCard>
            <Device ref="deviceView" @change="onChangedSite" prefix="swfm_"/>
        </CCard>
      </CCol>
      <CCol col="12" xl="5">
        <CCard>
          <GoogleMap ref="googleMap" style="height:822px; padding: 1%; overflow-y: auto;" />
        </CCard>
      </CCol>
    </CRow>
    <Event ref="eventView" @update="updateSummation" @click="onClickEvent" prefix="swfm_"/>
    <EventDialog ref="eventDialog" @click="onClickEvent"/>
  </div>
</template>


<script>
import Dashboard from "@/views/srds/Dashboard";
import Summation from "@/views/swfm/Summation";
import Device from "@/views/swfm/Device";
import Event from "@/views/swfm/Event";
import GoogleMap from '@/views/swfm/GoogleMap'
import EventDialog from "@/views/dashboard/EventDialog";

// import WarningList from '@/views/swfm/WarningList'

export default {
  name: "SWFMDashboard",
  extends: Dashboard,
  components: {
    Summation,
    Device,
    Event,
    GoogleMap,
    EventDialog
  },
  methods: {
    onChangedSite(siteTable) {
      // console.log('Dashboard::onChangedSite siteTable:', siteTable)
      this.selected_site_devices = siteTable;
      // var eventTable = this.$refs.eventView.getTable();
      // eventTable.data.forEach((event) => {
      //   // console.log('Dashboard::onChangedSite setMakerImage(event):', event)
      //   this.$refs.deviceView.setMarkerImage(event);
      // });
    },
    updateSummation(data) {
      var where = {
        and: [
          {
            status: 0,
            service_type: "srds",
          },
        ],
      };
      if (this.is_admin === false) {
        where.and.push({
          site_guid: {
            inq: this.user.site_guids,
          },
        });
      }
      this.$store
        .dispatch("rest/count", { model: "devices", where: where })
        .then((res) => {
          this.$refs.summationView.update(res.count, data);
        })
        .then(() => {
          var eventTable = this.$refs.eventView.getTable();
          eventTable.data.forEach((event) => {
            this.$refs.deviceView.setMarkerImage(event);
          });
        });
    },
  },
};
</script>

